
export const pixelate = (value) => `${value}px`;

export const MAX_WIDTH = 1440;
export const UNIT = 8;
export const SPACER = 5 * UNIT;
export const COLUMNS = 12;

export const SIZE = {
	small: 'small',
	medium: 'medium',
	large: 'large',
	xlarge: 'xlarge',
	max: 'max'
}

export const SIZES = [
	SIZE.small,
	SIZE.medium,
	SIZE.large,
	SIZE.xlarge,
	SIZE.max
]

export const MARGIN = {
	small: 24,
	medium: 40,
	large: 40,
	xlarge: 40,
	max: 40
};

export const GUTTER = {
	small: 24,
	medium: 40,
	large: 40,
	xlarge: 40,
	max: 40
};

export const BREAKPOINT = {
	medium: 760,
	large: 1240,
	xlarge: 1360,
	max: 1440
};

export const mediaQuery = {
	medium: `@media screen and (min-width: ${pixelate(BREAKPOINT.medium)})`,
	large: `@media screen and (min-width: ${pixelate(BREAKPOINT.large)})`,
	xlarge: `@media screen and (min-width: ${pixelate(BREAKPOINT.xlarge)})`,
	max: `@media screen and (min-width: ${pixelate(BREAKPOINT.max)})`
};

export const breakpoint = (size = SIZE.large, { pixelate = true } = { pixelate: true }) => pixelate ? `${BREAKPOINT[size]}px` : (BREAKPOINT[size]);
export const margin = (size = SIZE.large, { pixelate = true, multiplier = 1 } = { pixelate: true, multiplier: 1 }) => pixelate ? `${MARGIN[size] * multiplier}px` : (MARGIN[size] * multiplier);
export const gutter = (size = SIZE.large, { pixelate = true, multiplier = 1 } = { pixelate: true, multiplier: 1 }) => pixelate ? `${GUTTER[size] * multiplier}px` : (GUTTER[size] * multiplier);
export const unit = (value = 1, { pixelate = true } = { pixelate: true }) => pixelate ? `${value * UNIT}px` : (value * UNIT);
export const spacer = (value = 1, { pixelate = true } = { pixelate: true }) => pixelate ? `${value * SPACER}px` : (value * SPACER);
export const maxWidth = ({ pixelate = true } = { pixelate: true }) => pixelate ? `${MAX_WIDTH}px` : (MAX_WIDTH);

export const ZINDEX_COOKIE = 1000;
export const ZINDEX_NAVBAR = 900;
export const ZINDEX_CONTENT = 5;
