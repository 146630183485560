const isInternal = (url) => {
	// check against regex
	return /^\/(?!\/)/.test(url)
}

const tidyURL = (url) => {
	// remove this domain
	return url.replace(process.env.URL, '');
}

const standardiseInternalURL = (url) => {
	return url.replace(/\/?(\?|#|$)/, '/$1').toLowerCase();
}

const getGenericPath = (slug) => {
	return `/${slug ? `${slug}/` : ``}`
}

const getPropertyPath = (slug) => {
	return `/${slug ? `${slug}/` : ``}`
}

const getApartmentPath = (slug, property) => {
	return `/${slug && property ? `${property.slug}/${slug}/` : ``}`
}

const getLegalPath = (slug) => {
	return `/legal/${slug ? `${slug}/` : ``}`
}

const getTeamPath = () => {
	return `/team/`
}

const getContactPath = () => {
	return `/contact/`
}

const getBlogPath = (index = 0) => {
	return `/blog/${index > 0 ? `/${index + 1}/` : ``}`
}

const getCategoryPath = (slug, index = 0) => {
	return `/blog/category/${slug}${index > 0 ? `/${index + 1}/` : ``}`
}

const getArticlePath = (slug) => {
	return slug ? `/article/${slug}/` : `/blog/`;
}

const getCareerPath = () => {
	return `/careers/`
}

const getNewsPath = () => {
	return `/news/`
}

const getHomePath = () => {
	return `/`
}

const getLandingPath = (slug) => {
	return `/landing/${slug ? `${slug}/` : ``}`
}

const getConfirmationPath = (slug) => {
	return `/confirmation/${slug ? `${slug}/` : ``}`
}

const getCampaign2025Path = (slug) => {
	return `/${slug ? `${slug}/` : ``}`
}

const getPathFromModel = ({ model, slug, index, property }) => {
	if (model && model.apiKey) {
		switch (model.apiKey) {
			case 'generic': return getGenericPath(slug);
			case 'campaign2025': return getCampaign2025Path(slug);
			case 'team': return getTeamPath();
			case 'contact': return getContactPath();
			case 'home': return getHomePath();
			case 'legal': return getLegalPath(slug);
			case 'blog': return getBlogPath(index);
			case 'category': return getCategoryPath(slug, index);
			case 'article': return getArticlePath(slug);
			case 'news': return getNewsPath(slug);
			case 'career': return getCareerPath(slug);
			case 'landing': return getLandingPath(slug);
			case 'confirmation': return getConfirmationPath(slug);
			case 'property': return getPropertyPath(slug);
			case 'apartment': return getApartmentPath(slug, property);
		}
	}

	// Backup for all other types
	return '/'
}

const getEmailURL = (address) => {
	return `mailto:${address}`;
}

const getPhoneURL = (phone) => {
	return `tel:${phone.replace(/\s+/g, '')}`;
}

module.exports.getPathFromModel = getPathFromModel;

